<template>
    <div class="goodsList">
        <!--头部-->
        <nav-bar :title="$route.query.title || '产品列表'" url="/" :border=true :leftArrow=true></nav-bar>
        <!-- 内容 -->
        <div class="containerView-main">
            <div class="search-list">
                <div class="pro-con-list" v-if="listItem&&listItem.length>0">
                    <van-list
                        v-model="loading"
                        :finished="finished"
                        @load="onLoad"
                        :immediate-check="false"
                        :error.sync="error"
                        error-text="请求失败，点击重新加载"
                    >
                        <div class="list-box">
                            <div @click="goDetail(item)" class="list-item f-bgf" v-for="(item,index) in listItem" :key="index">
                                <div class="item-img">
                                    <img v-lazy="item.cover" alt="">
                                </div>
                                <div class="item-text">{{item.title}}</div>
                                <div class="item-price">
                                    <span class="price">￥{{item.price}}</span>
                                    <!-- <span class="icon-add-cart"></span> -->
                                </div>
                            </div>
                        </div>
                        <van-divider dashed class="botton-line" v-if="finished && listItem && listItem.length > 0">
                            到底了
                        </van-divider>
                    </van-list>
                </div>
                <div v-else class="order">
                    <no-data></no-data>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'goodsList',
  data () {
    return {
      loading: false, // 是否触发加载
      finished: false, // 数据加载完毕
      error: false, // 若列表数据加载失败，将error设置成true即可显示错误提示，用户点击错误提示后会重新触发 load 事件
      page: 1, // 页码
      listItem: []
    }
  },
  created () {
    this.init(this.page)// 初始化搜索
  },
  methods: {
    init (page) {
      let id = this.$store.state.search_id// 搜索id
      if (id) {
        sessionStorage.guid = id
      }
      this.$axios.post(`/v1/goods/boothList?page=${page}&token=${sessionStorage.token}`, {
        booth_id: id || sessionStorage.guid
      }).then((res) => {
        let data = res.data.data
        if (data.code === 1000) {
          if (page <= 1) {
            this.listItem = data.list
          } else {
            this.listItem.push.apply(this.listItem, data.list)
          }
          // 加载状态结束
          this.loading = false
          // 数据全部加载完成
          if (this.listItem.length === data.totalCount) {
            this.finished = true
          } else {
            this.finished = false
          }
        } else {
          this.error = true
        }
      })
    },
    // 下拉加载更多
    onLoad () {
      if (this.finished === false) {
        this.loading = true
        setTimeout(() => {
          if (!this.error) {
            this.page += 1
          }
          this.init(this.page)
        }, 1500)
      }
    },
    // 前往商品详情
    goDetail (item) {
      this.$router.push({
        path: '/goodsContent',
        query: {
          id: item.guid
        }
      })
    }
  },
  destroyed () {
    this.$Store.commit('searchId', '')
  }
}
</script>

<style lang="scss" scoped>
.goodsList{
    padding-top: 1rem;
}
</style>
